$(document).ready(() => {
  $('#add-info-error').hide()
  $('#edit-info-error').hide()

  $('#save-info').on('click', function (e) {
    if (!$.trim($('#id_note').val()).length && $('#file').get(0).files.length === 0) {
      $('#add-info-error').show()
      e.preventDefault()
    }
  })

  $('#edit-info').on('click', function (e) {
    if (!$.trim($('#id_note').val()).length) {
      $('#edit-info-error').show()
      e.preventDefault()
    }
  })
})
