import './css/_custom';
import './css/main';
import './css/utilities';
import '../common/styles/components/_tooltip';
import '../common/styles/components/_badge';
import '../common/styles/components/_footer';
import '../common/styles/components/_flexible';
import '../common/styles/components/_badge';
import '../common/styles/components/_components';
import '../wrangle/styles/components/_uploader';
import '../wrangle/styles/components/_opportunities-table';
import '../wrangle/styles/pages/_report';
import '../wrangle/styles/pages/_create-opportunity';
import '../wrangle/styles/pages/_opportunity';
import '../wrangle/styles/pages/_opportunities';
import '../wrangle/styles/pages/_opportunity-skills';
import '../wrangle/styles/pages/_request';
import '../wrangle/styles/pages/_login';
import '../wrangle/styles/pages/_team';

import './js/main';
import '../wrangle/opportunities';
import '../wrangle/opportunity';
import '../wrangle/opportunityInfo';
import '../wrangle/opportunity-skills';
import '../wrangle/uploader';
import '../wrangle/report';
import '../wrangle/shared'
import '../wrangle/login';
import '../wrangle/forgotPassword';
import '../wrangle/resetPassword';
import '../wrangle/sendProposal';
import '../wrangle/signup';
import '../common/shared';
import '../wrangle/team';